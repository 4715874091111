

































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { API } from '@/types';
import { msToTimeStr, removeDBTimestamps, timeStrToMS } from '@/util';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import EventInput from '@/components/Admin/EventInput.vue';
import UserInput from '@/components/Admin/UserInput.vue';
import { rootModule } from '@/store';
import { apiModify } from '@/api';

@Component({
  components: {
    ModifyDialog,
    TextInput,
    EventInput,
    UserInput,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.Players.GET | undefined;
  player: API.Players.POST = {};
  playerId: number | null = null;
  personalBestStr: string | null = null;
  err: boolean | string = false;
  token: string | null = null;

  // We store a string of the personal best but convert it into milliseconds.
  get personalBest(): string | null {
    return this.personalBestStr;
  }
  set personalBest(val: string | null) {
    this.personalBestStr = val;
    this.player.pb = val ? timeStrToMS(val) : undefined;
  }

  // Correctly formats anything entered into the "Personal Best" field when clicked off.
  pbBlur(): void {
    this.personalBest = this.player.pb ? msToTimeStr(this.player.pb) : null;
  }

  async generateNewToken(): Promise<void> {
    if (this.modifyItem) {
      const { data } = (await apiModify(
        'players', this.modifyItem.id, { newLivesplitAuthToken: true },
      ));
      this.$emit('post', data);
      this.token = data.livesplitAuthToken || null;
    }
  }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) {
      if (this.modifyItem) {
        this.player = removeDBTimestamps(this.modifyItem);
        this.playerId = this.modifyItem.id;
        this.personalBest = this.player.pb ? msToTimeStr(this.player.pb) : null;
        this.token = this.modifyItem.livesplitAuthToken || null;
      } else {
        this.player.eventId = rootModule.selectedEvent ?? undefined;
      }
    }
  }

  cancel(): void {
    this.personalBest = null;
    this.player = {};
    this.token = null;
    this.playerId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      const { data } = await apiModify('players', this.playerId, this.player);
      this.personalBest = null;
      this.player = {};
      this.playerId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
