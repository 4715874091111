
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  @Prop(Number) readonly value!: number;
  users: API.Users.GET[] = [];
  loading = false;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.users = (await apiGETMulti('users', {}, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }

  filter(user: API.Users.GET, queryText: string): boolean {
    const q = queryText.toLowerCase();
    return !!(user.name.toLowerCase().includes(q)
    || (user.twitch && user.twitch.toLowerCase().includes(q))
    || (user.twitter && user.twitter.toLowerCase().includes(q))
    || (user.discord && user.discord.toLowerCase().includes(q)));
  }
}
