











import { apiModify } from '@/api';
import { API } from '@/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop(Object) readonly player!: API.Players.GET;
  active = false;
  changing = false;

  created(): void {
    this.active = this.player.active;
  }

  @Watch('player.active')
  onPlayerActiveChange(val: boolean): void {
    this.active = val;
  }

  async change(state: boolean): Promise<void> {
    this.changing = true;
    try {
      const { data } = (await apiModify('players', this.player.id, { active: state }));
      this.active = data.active;
      this.$emit('post', data);
    } catch (err) {
      this.active = this.player.active;
    }
    this.changing = false;
  }
}
