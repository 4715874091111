


















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { msToTimeStr } from '@/util';
import ActiveState from './ActiveState.vue';
import OrderSelect from './OrderSelect.vue';
import DataTable from '@/components/Admin/DataTable.vue';
import { rootModule } from '@/store';
import { Socket } from 'vue-socket.io-extended';
import { apiGETSingle } from '@/api';

@Component({
  components: {
    DataTable,
    ActiveState,
    OrderSelect,
  },
})
export default class extends Vue {
  @Ref('DataTable') dataTable!: DataTable;
  players: API.Players.GET[] = []; // Only stores the current page of players!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyPlayer: API.Players.GET | null = null; // Player to be modified/deleted.
  options = {
    apiName: 'players',
    socketName: 'playerModified',
    idStr: 'id',
    embeds: [
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
      { name: 'event', socketName: 'eventModified', idStr: 'eventId' },
    ],
    headers: [
      { text: 'Name', value: 'user.name' },
      { text: 'Event', value: 'event.name' },
      { text: 'PB', value: 'pb' },
      { text: 'Created', value: 'creationTimestamp' },
      { text: 'Active State', value: 'active' },
    ],
    modifyComponent: Modify,
  };
  playerOrder: API.PlayerOrder.GET | null = null;
  dayjs = dayjs;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    if (this.selectedEvent !== null) {
      Vue.set(this, 'playerOrder', (await apiGETSingle('playerOrder', this.selectedEvent)).data);
    }
  }

  @Watch('selectedEvent', { immediate: true })
  onSelectedEventChange(val: number | null): void {
    // Force a reload if a specific event is selected.
    if (val !== null) this.loadAPIData();
  }

  // Sorting by player order only works if 1 page long, I think?
  get playersSorted(): API.Players.GET[] | undefined {
    if (!this.selectedEvent || !this.playerOrder) return undefined;
    return this.playerOrder.value
      .map((o) => this.players.find((p) => p.id === o))
      .filter((p) => !!p) as API.Players.GET[];
  }

  playerOrderChangePost(order: API.PlayerOrder.GET): void {
    Vue.set(this, 'playerOrder', order);
  }

  @Socket('playerOrderModified')
  socketPlayerOrderModified(val: API.PlayerOrder.GET | null): void {
    if (this.selectedEvent === val?.eventId) {
      Vue.set(this, 'playerOrder', val);
    }
  }
}
