var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{ref:"DataTable",attrs:{"items":_vm.players,"itemsSpecial":_vm.playersSorted,"tableOptions":_vm.tableOptions,"modifyItem":_vm.modifyPlayer,"selectedEvent":_vm.selectedEvent,"options":_vm.options},on:{"update:items":function($event){_vm.players=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event},"update:modifyItem":function($event){_vm.modifyPlayer=$event},"update:modify-item":function($event){_vm.modifyPlayer=$event}},scopedSlots:_vm._u([{key:"deleteConfirmationTitle",fn:function(){return [_vm._v(" Player Delete Confirmation: "),_c('v-chip',{staticClass:"ml-2 mr-2",attrs:{"input-value":true}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.modifyPlayer.event ? _vm.modifyPlayer.event.name : '?')+" ")],1),_vm._v(" "+_vm._s(_vm.modifyPlayer.user ? _vm.modifyPlayer.user.name : '?')+" ")]},proxy:true},{key:"deleteConfirmationText",fn:function(){return [_vm._v(" Are you sure you want to delete this player? ")]},proxy:true},{key:"addBtn",fn:function(){return [_vm._v("mdi-plus")]},proxy:true},{key:"addTooltip",fn:function(){return [_vm._v("Add New Player")]},proxy:true},{key:"additionalActions",fn:function(ref){
var item = ref.item;
return [(_vm.selectedEvent)?_c('order-select',{attrs:{"player":item,"order":_vm.playerOrder},on:{"post":_vm.socketPlayerOrderModified}}):_vm._e()]}},{key:"item.pb",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.msToTimeStr(item.pb))+" ")]}},{key:"item.creationTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.creationTimestamp).format('MMMM D, YYYY h:mm A'))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('active-state',{attrs:{"player":item},on:{"post":function($event){return _vm.dataTable.modifyPost($event)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }