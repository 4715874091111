














import { apiModify } from '@/api';
import { rootModule } from '@/store';
import { API } from '@/types';
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop(Object) readonly player!: API.Players.GET;
  @Prop(Object) readonly order!: API.PlayerOrder.GET | null;
  @Ref('Elem') elem!: HTMLElement;
  indexSet = 1;
  changing = false;

  created(): void {
    this.index = this.order?.value.find((p) => p === this.player.id) || 1;
  }

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get playerOrderItems(): number[] {
    return Array.from({ length: this.order?.value.length ?? 0 }, (_, i) => i + 1);
  }

  get index(): number {
    const index = this.order?.value.findIndex((p) => p === this.player.id);
    return index && index >= 0 ? index + 1 : 1;
  }
  set index(val: number) {
    this.indexSet = val;
  }

  async setSelected(): Promise<void> {
    this.changing = true;
    try {
      if (this.selectedEvent) {
        const { data } = await apiModify(
          'playerOrder',
          this.player.eventId,
          { playerId: this.player.id, index: (this.indexSet ?? 1) - 1 },
        );
        this.$emit('post', data);
      }
    } catch (err) {
      // Reset value somehow?
    }
    this.elem.blur();
    this.changing = false;
  }
}
